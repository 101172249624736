import { Heading, Text, Flex, Spinner } from '@scale/scaleui-radix';
import { signIn, useSession } from 'next-auth/react';
import { useAsync } from 'react-use';
import { parse } from 'qs';
import { TitleAndMetaTags } from '@components/TitleAndMetaTags';

function getURLSearchParams() {
  return typeof window !== 'undefined'
    ? (parse(window.location.search, { ignoreQueryPrefix: true }) as { callbackUrl?: string })
    : {};
}

export default function SignInPage() {
  const { status } = useSession();

  const { loading, error } = useAsync(async () => {
    if (status === 'loading') return;
    if (status === 'unauthenticated') {
      await signIn('okta', {
        callbackUrl: getURLSearchParams().callbackUrl ?? '/',
      });
    }
  }, [status]);

  return (
    <>
      <TitleAndMetaTags image="themes.png" title="Sign in - Scale UI Radix" />
      <div className="h-screen w-screen flex items-center justify-center">
        {error ? (
          <Flex align="center" direction="column" gap="4">
            <Heading size="3">There was an unexpected error!</Heading>
            <Text color="error">{error.message}</Text>
          </Flex>
        ) : loading || status === 'loading' || status === 'unauthenticated' ? (
          <Flex align="center" direction="column" gap="4">
            <Heading size="3">Signing Scale Okta...</Heading>
            <Spinner size="3" />
          </Flex>
        ) : null}
      </div>
    </>
  );
}
